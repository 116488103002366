import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, UnorderedList, ListItem, Text, Box } from "@chakra-ui/react"

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    height: { base: "100%", md: "100%", lg: "520px" },
    width: "100%",
    px: "24px",
  },
  "inner-box": {
    pt: { base: "80px", md: "80px", lg: "0px" },
    pb: { base: "80px", md: "80px", lg: "0px" },
    width: "100%",
    height: "auto",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: "center",
    justifyContent: "center",
  },
  "left-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    maxWidth: { base: "100%", md: "100%", lg: "430px" },
    flexDirection: "column",
  },

  uo: {
    justifyContent: {
      base: "center",
      md: "center",
      lg: "flex-start",
    },
    mt: { base: "16px", md: "16px", lg: "0px" },
  },

  text: {
    textAlign: { base: "center", md: "center", lg: "left" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#000000",
  },

  li: {
    fontSize: "16px",
    color: "#000000",
    lineHeight: "24px",
    letterSpacing: "0.15%",
  },

  image: {
    textAlign: "center",
    ml: { base: "0px", md: "0px", lg: "24px" },
    height: { base: "auto", md: "auto", lg: "235.31px" },
    width: { base: "100%", md: "100%", lg: "430px" },
  },
}

const SectionSolutions = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["left-box"]}>
        <Text sx={styles.text}>
          Our solutions are designed to help you seamlessly navigate the
          complexity of reimbursement structures under various bundled payment
          models, no matter where you are in the bundled payments evolution.
        </Text>
        <Text sx={styles.text} mt="24px">
          You can access our easy to use drill-down and roll-up analytics that
          exposes performance variation across patient populations individual
          physicians
        </Text>
        <Flex sx={styles.uo}>
          <UnorderedList>
            <ListItem sx={styles.li}>patient populations</ListItem>
            <ListItem sx={styles.li}>individual physicians </ListItem>
            <ListItem sx={styles.li}>and post-acute care providers</ListItem>
          </UnorderedList>
        </Flex>

        <Text sx={styles.text} mt="24px">
          Through our integration of your claims data with our CMS claims data
          set, you can evaluate your participation in specific bundled payment
          programs and maximize performance within various bundled payment
          arrangements.
        </Text>
      </Flex>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/bpci/bpci-hero-c.png"
          alt="BPCI Advanced hero"
          placeholder="blurred"
        />
      </Box>
    </Flex>
  </Box>
)

export default SectionSolutions
