import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Text, Box } from "@chakra-ui/react"
import merge from "lodash/merge"

const defaultStyles = {
  root: {
    alignItems: "center",
    height: "72px",
    boxSizing: "border-box",
    border: "1px solid #DCE0E5",
    borderRadius: "8px",
    px: "16px",
  },
  icon: {
    minHeight: "40px",
    minWidth: "40px",
    height: "40px",
    width: "40px",
  },
  text: {
    ml: "16px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
}

const Highlight = ({ Image, text, styles, TextComponent }) => {
  const _styles = merge({}, defaultStyles, styles)

  return (
    <Flex sx={_styles.root}>
      <Box sx={_styles.icon}>
        <Image />
      </Box>
      {TextComponent ? (
        <TextComponent />
      ) : (
        <Text sx={_styles.text}>{text}</Text>
      )}
    </Flex>
  )
}

Highlight.defaultProps = {
  Image: () => (
    <StaticImage
      src="../images/checked.png"
      alt="Checkmark"
      placeholder="blurred"
    />
  ),
  styles: {},
}

export default Highlight
