import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Box, Text, Link, chakra } from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"
import Highlight from "../highlight"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "696px" },
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    px: "24px",
  },
  "inner-box": {
    mt: { base: "40px", md: "40px", lg: "0px" },
    mb: { base: "80px", md: "80px", lg: "0px" },
    maxWidth: { base: "100%", md: "100%", lg: "1339px" },
    height: { base: "100%", md: "100%", lg: "auto" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: { base: "flex-start", md: "flex-start", lg: "center" },
    justifyContent: "center",
  },
  hero: {
    textAlign: "center",
    width: { base: "100%", md: "100%", lg: "658px" },
    height: { base: "auto", md: "auto", lg: "488px" },
    minWidth: { base: "100%", md: "100%", lg: "405px" },
    minHeight: { base: "auto", md: "auto", lg: "300px" },
  },
  "right-box": {
    ml: { base: "0px", md: "0px", lg: "24px" },
    flexDirection: "column",
    maxWidth: { base: "100%", md: "100%", lg: "658px" },
    height: "100%",
  },
  highlight: {
    root: {
      mt: "16px",
      "&:nth-child(4)": {
        height: { base: "auto", md: "auto", lg: "128px" },
        py: { base: 3, md: 3, lg: "0px" },
      },
    },
  },
  text: {
    textAlign: { base: "center", md: "center", lg: "left" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
  "highlight-text": {
    ml: "16px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
  bold: {
    fontWeight: "bold",
  },
  whitespace: { pb: "24px" },
  link: {
    color: "#3399FF",
    display: "inline-flex",
    alignItems: "center",
  },
}

const TextA = () => (
  <Text sx={styles["highlight-text"]}>
    <chakra.span sx={styles.bold}>
      Episode-based Payment Initiatives for Medicare —
    </chakra.span>{" "}
    Bundled Payments for Care Improvement (BPCI) Advanced, Comprehensive Care
    for Joint Replacement.{" "}
    <Link
      as={GatsbyLink}
      sx={styles.link}
      isExternal
      href="https://innovation.cms.gov/innovation-models/bpci-advanced"
    >
      read the article
      <ExternalLinkIcon ml="4px" />
    </Link>
  </Text>
)

const TextB = () => (
  <Text sx={styles["highlight-text"]}>
    <chakra.span sx={styles.bold}>State-level models —</chakra.span> For
    instance, those in Arkansas, Tennessee, Ohio and New York
  </Text>
)

const TextC = () => (
  <Text sx={styles["highlight-text"]}>
    <chakra.span sx={styles.bold}>
      Bundled models with commercial payers
    </chakra.span>
  </Text>
)

const TextD = () => (
  <Text sx={styles["highlight-text"]}>
    <chakra.span sx={styles.bold}>Employer-based bundles — </chakra.span>For
    instance, Walmart’s Centers of Excellence
  </Text>
)

const SectionExplanation = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Box sx={styles.hero}>
        <StaticImage
          src="../../images/bpci/bpci-hero-b.png"
          alt="BPCI-Advanced hero image"
          placeholder="blurred"
        />
      </Box>
      <Flex sx={styles["right-box"]}>
        <Text sx={styles.text}>
          Providers stand to significantly benefit by redesigning care that
          enhances coordination among a patient’s care team, and optimizes the
          post-acute care under these alternative payment methodologies called
          bundled payments.
        </Text>
        <Text sx={styles.whitespace}></Text>
        <Text sx={styles.text}>
          Bundled payments are a promising alternative to the traditional
          fee-for-service payment model, which include programs such as
        </Text>
        <Highlight TextComponent={TextA} styles={styles.highlight} />
        <Highlight TextComponent={TextB} styles={styles.highlight} />
        <Highlight TextComponent={TextC} styles={styles.highlight} />
        <Highlight TextComponent={TextD} styles={styles.highlight} />
      </Flex>
    </Flex>
  </Box>
)

export default SectionExplanation
