import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { Flex, Text, Heading, Box, Button } from "@chakra-ui/react"

const styles = {
  root: {
    height: { base: "100%", md: "100%", lg: "450px" },
    width: "100%",
    bg: "#F5F5F7",
    px: "24px",
    overflowX: "hidden",
    overflowY: "scroll",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "::-webkit-scrollbar": {
      display: "none",
    },
  },
  "inner-box": {
    margin: "0 auto",
    mt: { base: "40px", md: "40px", lg: "0px" },
    mb: { base: "40px", md: "40px", lg: "0px" }, // Remove this when company logos come back
    maxWidth: { base: "100%", md: "100%", lg: "1111px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  },
  "right-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    width: { base: "100%", md: "100%", lg: "430px" },
    flexDirection: "column",
    justifyContent: "center",
  },
  heading: {
    fontWeight: "bold",
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#1B263F",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  text: {
    mt: "24px",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  hero: {
    mr: { base: "0px", md: "0px", lg: "130px" },
    height: { base: "auto", md: "auto", lg: "150px" },
    width: { base: "100%", md: "100%", lg: "543px" },
  },
  logos: {
    margin: "0 auto",
    mt: { base: "47px", md: "47px", lg: "87px" },
    height: { base: "100%", md: "100%", lg: "76.49px" },
    maxWidth: { base: "100%", md: "100%", lg: "1339px" },
    flexDirection: { base: "column", md: "column", lg: "row" },
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    mt: { base: "40px", md: "40px", lg: "0px" },
    width: "205px",
    height: "auto",
    "&:nth-child(2)": {
      mx: { base: "0px", md: "0px", lg: "20px" },
    },
    "&:nth-child(4)": {
      mx: { base: "0px", md: "0xp", lg: "20px" },
    },
    "&:last-child": {
      mb: { base: "80px", md: "80px", lg: "0px" },
    },
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
}

const navigateToContactUs = () => navigate("/contact-us")

const SectionBestCompanies = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Box sx={styles.hero}>
        <StaticImage
          src="../../images/homepage/hp-hero-b.png"
          alt="Hero Image"
          sx={styles.hero}
        />
      </Box>
      <Flex sx={styles["right-box"]}>
        <Heading sx={styles.heading}>
          The best hospitals in the country choose to work with us.
        </Heading>
        <Text sx={styles.text}>Want to see BPCI Advanced in action?</Text>
        <Button sx={styles.button} onClick={navigateToContactUs}>
          Schedule a Demo
        </Button>
      </Flex>
    </Flex>
    {/* <Flex sx={styles.logos}>
      <Box sx={styles.logo}>
        <StaticImage
          src="../../images/bpci/bilh.png"
          alt="Beth Israel Laney Health"
          sx={styles.logo}
        />
      </Box>
      <Box sx={styles.logo}>
        <StaticImage
          src="../../images/bpci/hfhs.png"
          alt="Henry Ford Health System"
          sx={styles.logo}
        />
      </Box>
      <Box sx={styles.logo}>
        <StaticImage
          src="../../images/bpci/mgb.png"
          alt="Mass General Brigham"
          sx={styles.logo}
        />
      </Box>
      <Box sx={styles.logo}>
        <StaticImage
          src="../../images/bpci/ucla.png"
          alt="UCLA Health"
          sx={styles.logo}
        />
      </Box>
      <Box sx={styles.logo}>
        <StaticImage
          src="../../images/bpci/psh.png"
          alt="Penn State Health"
          sx={styles.logo}
        />
      </Box>
    </Flex> */}
  </Box>
)

export default SectionBestCompanies
