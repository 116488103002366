import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Flex, Button, Heading, Text, Box } from "@chakra-ui/react"

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    height: { base: "100%", md: "100%", lg: "591px" },
    width: "100%",
    px: { base: "24px", md: "24px", lg: "10%" },
    bg: "#F4F4F4",
  },
  "inner-box": {
    pt: { base: "80px", md: "80px", lg: "0px" },
    pb: { base: "80px", md: "80px", lg: "0px" },
    width: "100%",
    height: "auto",
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
    justifyContent: "space-between",
  },
  "left-box": {
    mt: { base: "47px", md: "47px", lg: "0px" },
    maxWidth: { base: "100%", md: "100%", lg: "428px" },
    flexDirection: "column",
  },
  heading: {
    fontWeight: "bold",
    textAlign: { base: "center", md: "center", lg: "left" },
    fontSize: { base: "24px", md: "24px", lg: "40px" },
    lineHeight: { base: "32px", md: "32px", lg: "48px" },
    color: "#000000",
  },
  text: {
    mt: "24px",
    textAlign: { base: "center", md: "center", lg: "left" },
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#041424",
  },
  button: {
    mt: "24px",
    padding: "8px 16px",
    bg: "#129459",
    height: { base: "40px", md: "40px", lg: "56px" },
    width: "100%",
    borderRadius: "4px",
    color: "#F1F1F1",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "bold",
    outline: "none",
    "&:hover,&:active,&:focus": {
      bg: "#129459",
      outline: "none",
      border: "none",
    },
  },
  image: {
    textAlign: "center",
    ml: { base: "0px", md: "0px", lg: "20px" },
    height: { base: "auto", md: "auto", lg: "431px" },
    width: { base: "100%", md: "100%", lg: "431px" },
  },
}

const navigateToHBRArticle = () => {
  const a = document.createElement("a")
  a.href =
    "https://hbr.org/2021/03/how-one-hospital-has-succeeded-in-a-world-of-bundled-payments"
  a.rel = "noopener"
  a.target = "_blank"
  a.click()
}

const SectionHBR = () => (
  <Box sx={styles.root}>
    <Flex sx={styles["inner-box"]}>
      <Flex sx={styles["left-box"]}>
        <Heading sx={styles.heading}>
          How One Hospital Has Succeeded in a World of Bundled Payments
        </Heading>
        <Text sx={styles.text}>
          The pace of the transition from fee-for-service to value-based
          payments for health care is virtually certain to accelerate in coming
          years. Consequently, care providers will have to learn how to succeed
          in the new environment.
        </Text>
        <Button sx={styles.button} onClick={navigateToHBRArticle}>
          Read the HBR Article
        </Button>
      </Flex>
      <Box sx={styles.image}>
        <StaticImage
          src="../../images/bpci/bpci-hbr.png"
          alt="Harvard Business Review logo"
          placeholder="blurred"
        />
      </Box>
    </Flex>
  </Box>
)

export default SectionHBR
