import React from "react"

import Layout from "../components/layout"
import SectionHero from "../components/bpci-advanced/section-hero"
import SectionExplanation from "../components/bpci-advanced/section-explanation"
import SectionHBR from "../components/bpci-advanced/section-hbr"
import SectionSolutions from "../components/bpci-advanced/section-solutions"
import SectionBestCompanies from "../components/bpci-advanced/section-best-companies"

class BPCIAdvancedIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} title={"BPCI Advanced"}>
        <SectionHero />
        <SectionExplanation />
        <SectionHBR />
        <SectionSolutions />
        <SectionBestCompanies />
      </Layout>
    )
  }
}

export default BPCIAdvancedIndex
